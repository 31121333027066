import React from 'react';
import './LandingPage.css';

const LandingPage = () => {
  return (
    <>
      <div className="background-wrapper"></div>
      <div className="container">
        <h1 className="main-title">Dream Atlas</h1>
        <p className="tagline">Map the Meaning of Your Dreams With AI</p>
        <a href="https://t.me/dreamatlas_bot" 
          className="cta-button" 
          target="_blank" 
          rel="noopener noreferrer">
          Start Your Dream Journey
        </a>

        <p className="text-block">
          Receive in-depth dream readings and personalized insights rooted in Jungian psychology.
        </p>

        <div className="steps-section">
          <div className="step-card">
            <span className="step-number">1</span>
            <p>Share your dream</p>
          </div>
          <div className="step-card">
            <span className="step-number">2</span>
            <p>Answer personalized questions</p>
          </div>
          <div className="step-card">
            <span className="step-number">3</span>
            <p>Get AI analysis</p>
          </div>
          <div className="step-card">
            <span className="step-number">4</span>
            <p>Visualize your dream</p>
          </div>
        </div>
        
        <div className="feature-section">
          <p className="section-title">Features:</p>
          <div className="feature-cards">
            <div className="feature-card">
              <span className="feature-emoji">🧠</span>
              <p>In-Depth Jungian Dream Analysis</p>
            </div>
            <div className="feature-card">
              <span className="feature-emoji">📱</span>
              <p>Voice Messages</p>
            </div>
            <div className="feature-card">
              <span className="feature-emoji">🌍</span>
              <p>Multi-Language Support</p>
            </div>
            <div className="feature-card">
              <span className="feature-emoji">🎨</span>
              <p>Dream Image Generation</p>
            </div>
          </div>
        </div>

        <div className="contact-block">
          <h3>Contact Us, Get Updates, and Connect with Fellow Dreamers:</h3>
          <a href="https://t.me/+B9Jo5nsMe5BjYjQx" 
            className="secondary-cta" 
            target="_blank" 
            rel="noopener noreferrer">
            Join Our Telegram Community
          </a>
        </div>
      </div>
    </>
  );
};

export default LandingPage;