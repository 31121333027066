import React from 'react';
import LandingPage from './components/LandingPage';


function App() {
  return (
    <div className='App'>
      <LandingPage />
    </div>
  )
}

export default App;
